let cart = {
    products: [],
    totalPrice: 0
}


const calculateTotal = () => { 
    cart.totalPrice = 0
    let price = 0
    cart.products.map((p) => {
        if (p.saleAmount > 0 && p.count > 1) {
            price = p.salePrice * p.count
        } else {
            if(isNaN(p.price)) return
            price = p.price * p.count
        }
        cart.totalPrice += price
        
    })
}

const updateProducts = (el, id) => { 
    cart.products.map(p => { 
        if (p.id === id) { 
            p.count = el.value
            console.log(p, el.value);            
        }
        calculateTotal()
        updateCart()      
    })
}

const updateCart = () => { 
    const cartContainer = document.getElementById('cart')
    const form = document.getElementById('form')
    let output = ""
    cart.products.map(p => { 
        output += `
            <div class="flex--inputs">
                <div class="input--group large">
                    <input type="text" name="batterie[]" id="batterie-${p.id}" required value="${p.batterie}" readonly>
                    <input type="hidden" name="price[]" value="${isNaN(p.price) ? 'Preis auf Anfrage' : p.price}">
                </div>            
                <div class="input--group small">
                    <input type="number" name="menge[]" id="menge-${p.id}" value="${p.count}" min="1" max="${p.max}" maxlength="2" onchange="updateProducts(this, '${p.id}')">
                </div>
                <div class="input--group last">
                    <div class="delete custom-background" onclick="deleteItem('${p.id}')">-</div>
                </div>
            </div>`
    })
    cartContainer.innerHTML = output
    let totalPrice = (Math.round(cart.totalPrice * 100) / 100).toFixed(2).replace('.', ',')
    let totalOutput = 'Gesamt: € ' + totalPrice
    if(cart.products.length > 0 && totalPrice === '0,00') totalOutput = '<small>Preis auf Anfrage</small>'
    cartContainer.innerHTML += `<div class="total"><h4>${totalOutput}</h4></div><input type="hidden" name="total" value="${totalPrice}">`
    saveCart()
}

const addToCart = (el, id, price, max, sale, saleNum) => { 
   
    let num = parseFloat(price.replace(',', '.'))
    num = (Math.round(num * 100) / 100).toFixed(2)

    let productExists = false
    let salePrice = ""
    let saleAmount = ""
    cart.products.map(p => { 
        if (p.id === id) { 
            productExists = true
            p.count++
        }
    })

 

    if (!productExists) { 

        if (sale) { 
            salePrice = parseFloat(sale.replace(',', '.'))
            salePrice = (Math.round(salePrice * 100) / 100).toFixed(2)
            saleAmount = parseInt(saleNum)
        }
        cart.products.push({
            batterie: el,
            price: num,
            count: 1,
            id: id,
            max,
            salePrice,
            saleAmount
        })
      
    }
    
    calculateTotal()
    updateCart()
    const form = document.getElementById('form')
    form.scrollIntoView({ behavior: 'smooth' })

}

const deleteItem = (id) => { 
    const items = cart.products
    items.map((item, index) => { 
        if (item.id === id) { 
            items.splice(index, 1)
        }
    })
    calculateTotal()
    updateCart()
}

const saveCart = () => { 
    sessionStorage.setItem('bnh--cart', JSON.stringify(cart))
    console.log(JSON.parse(sessionStorage.getItem('bnh--cart')));
}

const loadCart = () => { 
    if (sessionStorage.getItem('bnh--cart')) { 
        cart = JSON.parse(sessionStorage.getItem('bnh--cart'))
    }
    updateCart()
}
const clearCart = () => { 
    let cart = {
        products: [],
        totalPrice: 0
    }
    sessionStorage.clear()
}

loadCart()

const toggleAlternativeAdress = () => { 
    document.querySelector('.abweichende_adresse').classList.toggle('active')
}